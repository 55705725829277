<h1 class="screen_out">wise convey</h1>
<ul class="list_head">
  <li #menuProfile>
    <a class="ico_comm btn_login">login</a>
    <a
      class="box_logout"
      [@animationProfile]="menuProfileState"
      [style]="
        menuProfileState === 'opened'
          ? {
              display: 'block'
            }
          : {
              display: 'none'
            }
      "
      (click)="onClickLogout($event)"
    >
      <span class="txt_logout">Logout</span>
    </a>
  </li>
  <li>
    <button type="button" class="ico_comm btn_chat" (click)="onOpenCoreChat($event)">corechat</button>
  </li>
</ul>
