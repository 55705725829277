<ul class="list_menu">
  <li>
    <a class="link_lnb" [routerLink]="['/ai-scanning']" routerLinkActive="lnb_link_open"
      ><!-- 클릭시 lnb_link_open 클래스 추가(2depth open) -->
      <span class="txt_menu">AI Scanning</span>
    </a>
  </li>
  <li>
    <a class="link_lnb" [routerLink]="['/export-excel']" routerLinkActive="lnb_link_open"
      ><!-- 클릭시 lnb_link_open 클래스 추가(2depth open) -->
      <span class="txt_menu">Export to Excel</span>
    </a>
    <!-- <ul class="list_menu_depth">
      <li>
        <a>2depth menu1</a>
      </li>
      <li>
        <a>2depth menu2</a>
      </li>
    </ul> 2depth 사용시 주석제거-->
  </li>
</ul>
